<template>
    <v-form>

        <error-alert v-if="!_.isNil(summaryError)" :message="summaryError" />

        <v-switch
          v-model="form.active"
          :color="$const.color.primary"
          label="Активность"
        ></v-switch>

        <v-select
          v-model="$v.form.grade.$model"
          :error-messages="getErrors('form.grade')"
          :items="grades"
          :menu-props="{ maxHeight: '500' }"
          label="Класс"
          :color="$const.color.primary"
        >
        </v-select>

        <v-text-field 
            v-model="$v.form.name.$model"
            :error-messages="getErrors('form.name')"
            label="Название"
            name="name" 
            type="text"
            :placeholder="namePlaceholder"
            autofocus="autofocus"
            :color="$const.color.primary"
        ></v-text-field>

        <v-select
          v-model="form.student_id"
          :items="students"
          :menu-props="{ maxHeight: '500' }"
          label="Учащиеся"
          multiple
          chips
          hint="Выберите учащихся класса"
          persistent-hint
          :loading="loading"
          :color="$const.color.primary"
        >
            <template v-slot:prepend-item>
                <v-list-item ripple @click="selectAll('form.student_id', students)">
                    <v-list-item-action>
                        <v-icon :color="form.student_id.length > 0 ? 'indigo darken-4' : ''">
                            {{ isSelectedAll('form.student_id', students) ? 'mdi-close-box' : (isSelectedNone('form.student_id') ? 'mdi-checkbox-blank-outline' : 'mdi-minus-box') }}
                        </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            Все
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
            </template>
        </v-select>

        <v-select
          v-model="form.teacher_id"
          :items="teachers"
          :menu-props="{ maxHeight: '500' }"
          label="Педагоги"
          multiple
          chips
          hint="Выберите педагогов, имеющих доступ к данному классу"
          persistent-hint
          :loading="loading"
          :color="$const.color.primary"
        >
            <template v-slot:prepend-item>
                <v-list-item ripple @click="selectAll('form.teacher_id', teachers)">
                    <v-list-item-action>
                        <v-icon :color="form.student_id.length > 0 ? 'indigo darken-4' : ''">
                            {{ isSelectedAll('form.teacher_id', teachers) ? 'mdi-close-box' : (isSelectedNone('form.teacher_id') ? 'mdi-checkbox-blank-outline' : 'mdi-minus-box') }}
                        </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>
                            Все
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
            </template>
        </v-select>

        <action-buttons 
            :waiting-save-and-back="waiting.save.back"
            :waiting-save-and-update="waiting.save.update"
            @back="back"
            @save="save"
        />

        <error-alert v-if="error" :message="error" />
    </v-form>
</template>
<script>
import { errorMixin, saveMixin } from '@/mixins/formMixin'
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
import ActionButtons from '@/components/crud/ActionButtons.vue'
import ErrorAlert from '../template/ErrorAlert.vue'

export default {
    name: 'GroupForm',
    components: { ActionButtons, ErrorAlert },
    props: {
        model: {
            type: Object
        }
    },
    mixins: [errorMixin, saveMixin, validationMixin],
    data() {
        return {
            loading: false,
            error: null,
            storeModule: 'group',
            teachers: [],
            students: [],
            form: {
                active: true,
                name: '',
                grade: null,
                teacher_id: [],
                student_id: [] 
            }
        };
    },
    computed: {
        namePlaceholder () {
            return this.form.grade ? `Например, ${this.form.grade}А` : ''
        },
        grades() {
            return Array.from(Array(8).keys()).map(n => {
                return {
                    text: `${n + 1} класс`,
                    value: n + 1
                };
            })
        }
    },
    validations() {
        return {
            form: {
                name: {
                    required,
                    minLength: minLength(2)
                },
                grade: { required }
            }
        }
    },
    methods: {
        selectAll(field, allItems) {
            this.$nextTick(() => {
                const items = _.get(this, field, null);
                if(_.isNil(items))
                    return ;

                if(this.isSelectedAll(field, allItems))
                    _.set(this, field, []);
                else
                    _.set(this, field, _.map(allItems, 'value'));
            });
        },
        isSelectedAll(field, allItems) {
            return _.size(_.get(this, field, null)) === _.size(allItems);
        },
        isSelectedNone(field) {
            return _.size(_.get(this, field, null)) <= 0;
        }
    },
    async created() {
        try {
            this.loading = true;
            const [rqTeachers, rqStudents] = await Promise.all([
                this.$store.dispatch('user/list', { filter: { role: 'teacher' }, pagination: 0, fields: 'id,name' }),
                this.$store.dispatch('user/list', { filter: { role: 'student' }, pagination: 0, fields: 'id,name' }),
            ])
            
            const formatItemsToList = (items) => {
                return _.map(items, (item) => ({
                    value: item.id,
                    text: item.name
                }));
            }
            
            this.teachers = formatItemsToList(_.get(rqTeachers, 'data.items', []));
            this.students = formatItemsToList(_.get(rqStudents, 'data.items', []));
        } catch (e) {
            console.error(e);
            this.error = e;
        } finally {
            this.loading = false;
        }
    }
}
</script>
<style scoped lang="scss">

</style>